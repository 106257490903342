import {useEffect} from 'react';
import {useFormContext} from 'providers/formProvider';

interface UseFormUpdateOutput<T> {
  formValue: T;
  formError?: string;
  setFormValue: (newValue: T) => void;
  setFormError: (error: string) => void;
}

export function useFormUpdate<T>(
  nameSpace: string,
  key: string,
  initialValue?: T,
  diffsOnlyMode?: boolean,
): UseFormUpdateOutput<T> {
  const {state, updateValue, updateError} = useFormContext();

  // optionally set the starting value
  useEffect(() => {
    if (!diffsOnlyMode) {
      updateValue(nameSpace, key, initialValue);
    }
  }, []);

  // Form value is whatever is stored, or if the value stored is undefiend, the
  // initialValue
  const storedValue = state[nameSpace]?.values[key];
  const formValue = storedValue !== undefined ? storedValue : initialValue;
  const formError = state[nameSpace]?.errors[key];

  return {
    formValue,
    formError,
    setFormValue: (newValue: T) => {
      // if diffsOnlyMode is set, any time the updated value is the same as
      // intial value, we erase the stored form entry.
      if (
        diffsOnlyMode &&
        initialValue !== undefined &&
        newValue === initialValue
      ) {
        updateValue(nameSpace, key, undefined);
      } else {
        if (newValue !== formValue) {
          updateValue(nameSpace, key, newValue);
        }
      }
    },
    setFormError: (error: string) => {
      updateError(nameSpace, key, error);
    },
  };
}

export function useFormValue<T>(nameSpace: string, key: string): T | null {
  const {state} = useFormContext();
  const formValues = state?.[nameSpace]?.values;
  if (!formValues) return null;
  return formValues[key];
}

export default useFormUpdate;
