import React, {useState} from 'react';
import styles from './select.module.scss';
import Button from 'components/ui/button';
import DropDown from 'components/ui/dropdown';
import DropDownIndicator from 'public/icons/dropdown-indicator.svg';
import MarkdownText from 'components/ui/markdownText';
import {SimpleKV} from 'types';

export interface SelectInputOption {
  label?: string;
  labelFunc?: () => JSX.Element;
  value: string | number;
  renderFun?: (selectedCallback: (newValue: any) => void) => React.ReactNode;
  disabled?: boolean;
  dataTestId?: string;
}

type SelectSize = 'normal' | 'large';

export interface SelectInputProps {
  label?: string;
  disabled?: boolean;
  className?: string;
  error?: boolean;
  message?: string;
  notApplicableClassName?: string;
  errorClassName?: string;
  placeholder?: string;
  onChange: (newValue: any) => void;
  selected: SelectInputOption['value'];
  options: SelectInputOption[];
  selectButtonClassName?: string;
  containerClassName?: string;
  hideMessageSpacing?: boolean; // Hide the message spacing below the input
  hideLabelSpacing?: boolean; // Hide the label spacing above the input
  selectSize?: SelectSize;
  onBlur?: () => void;
  id?: string;
  inline?: boolean;
  fullWidth?: boolean;
  enableScroll?: boolean;
  alwaysStatic?: boolean;
  'data-test'?: string;
  messageClassName?: string;
  dropDownProps?: SimpleKV;
  labelClassName?: string;
}

const SelectInput = (props: SelectInputProps) => {
  const {
    containerClassName,
    selectButtonClassName,
    messageClassName,
    className,
    placeholder,
    error,
    message,
    onChange,
    disabled,
    label,
    options,
    selected,
    hideLabelSpacing,
    hideMessageSpacing,
    inline,
    selectSize = 'normal',
    onBlur,
    id,
    fullWidth,
    dropDownProps,
    labelClassName,
    alwaysStatic,
    enableScroll,
  } = props;
  const classNames = [styles.select, styles[selectSize], className];
  const containerClassNames = [
    styles.selectLabel,
    containerClassName,
    styles[selectSize],
  ];
  const labelClassNames = [styles.selectLabelText, styles[selectSize]];
  if (labelClassName) {
    labelClassNames.push(labelClassName);
  }
  const messageClassNames = [
    styles.message,
    styles[selectSize],
    messageClassName,
  ];
  const [show, setShow] = useState(false);

  const buttonClasses = [];

  if (error) {
    buttonClasses.push(styles.buttonError);
    labelClassNames.push(styles.error);
    messageClassNames.push(styles.error);
  }

  if (disabled) {
    classNames.push(styles.disabled);
  }

  if (inline) {
    containerClassNames.push(styles.inline);
  }

  if (fullWidth) {
    containerClassNames.push(styles.fullWidth);
  }

  if (alwaysStatic) {
    buttonClasses.push(styles.static);
  }
  const labelsByValues = options.reduce((acc, option) => {
    acc[option.value] = option.label;
    return acc;
  }, {});

  const selectedLabel =
    selected || selected == 0 ? labelsByValues[selected] : placeholder;

  const optionButtonClassNames = [
    styles.optionButton,
    styles[selectSize],
    styles.optionLabel,
  ];

  const dropDrownClassNames = [styles.dropdown];
  if (hideLabelSpacing) {
    optionButtonClassNames.push(styles.hideLabelSpacing);
  }

  if (enableScroll) {
    buttonClasses.push(styles.relativePosition);
    dropDrownClassNames.push(styles.positionAbsolute);
  }

  return (
    <label
      className={containerClassNames.join(' ')}
      onClick={(e) => e.preventDefault()}>
      {!hideLabelSpacing && (
        <p className={labelClassNames.join(' ')}>{label}</p>
      )}
      <Button
        data-test={props['data-test'] ?? 'select-button'}
        data-test-id={props['data-test-id'] ?? 'id'}
        outline
        dataAttributes={{'data-selected-value': selected}}
        id={id}
        disabled={disabled}
        className={[
          ...buttonClasses,
          styles.selectButton,
          selectButtonClassName,
          styles[selectSize],
          fullWidth && styles.fullWidth,
        ].join(' ')}
        onClick={(e) => {
          e.preventDefault();
          setShow(!show);
        }}
        iconTrailing
        onBlur={onBlur}
        iconRenderFun={() =>
          disabled ? (
            <span className={styles.indicatorSpacer} />
          ) : (
            <DropDownIndicator />
          )
        }
        title="Open dropdown">
        {
          <span className={styles.optionLabel}>
            <MarkdownText text={selectedLabel} />
          </span>
        }
        {show && (
          <DropDown
            className={dropDrownClassNames.join(' ')}
            lockScroll={!enableScroll}
            disableFixedPosition={enableScroll}
            ignoreTop={enableScroll}
            ignoreLeft={enableScroll}
            onClickOutside={() => setShow(false)}
            {...dropDownProps}>
            <ul data-test="select" className={styles.optionsList}>
              {options.map((option) => {
                const isSelected = option.value === selected;
                const optionClasses = [styles.option];
                if (option.disabled) {
                  optionClasses.push(styles.optionDisabled);
                }
                if (isSelected) {
                  optionClasses.push(styles.isSelected);
                }
                return (
                  <li
                    data-test="select-option"
                    data-test-id={option.dataTestId || option.label}
                    key={'select-' + option.label}
                    className={optionClasses.join(' ')}>
                    {option.renderFun ? (
                      option.renderFun(onChange)
                    ) : (
                      <button
                        className={optionButtonClassNames.join(' ')}
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(false);
                          onChange(option.value);
                        }}
                        title={
                          !!option.labelFunc
                            ? String(option.labelFunc())
                            : option.label
                        }>
                        {/* if we have a label func, generate it now, otherwise use the label string  */}
                        {!!option.labelFunc ? (
                          option.labelFunc()
                        ) : (
                          <MarkdownText text={option.label} />
                        )}
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
          </DropDown>
        )}
      </Button>
      {!hideMessageSpacing && (
        <p className={messageClassNames.join(' ')}>{message}</p>
      )}
    </label>
  );
};

export default SelectInput;
