import React, {useRef} from 'react';
import CSS from 'csstype';
import styles from './checkbox.module.scss';
import Button from 'components/ui/button';
import FatCheck from 'public/icons/check-fat.svg';

const Checkbox = (props: {
  checked: boolean;
  onChange: (newValue: boolean) => void;
  className?: string;
  invertColors?: boolean;
  disabled?: boolean;
  'data-test'?: string;
}) => {
  const {checked, onChange, className, invertColors, disabled} = props;
  const buttonClassName = [styles.checkboxButton];

  const checkRef = useRef<HTMLInputElement>();
  let style: CSS.Properties = {};
  let checkboxStyle: CSS.Properties = {};

  if (checked) {
    buttonClassName.push(styles.checked);
    if (invertColors) {
      buttonClassName.push(styles.invertColors);
    }
  }

  return (
    <label className={[styles.checkbox, className].join(' ')}>
      <input
        ref={checkRef}
        checked={checked}
        type={'checkbox'}
        onChange={(e) => {
          if (!disabled) {
            onChange(e.target.checked);
          }
        }}
      />
      <Button
        data-test={props['data-test']}
        data-checked={checked ? true : false}
        outline
        className={buttonClassName.join(' ')}
        disabled={disabled}
        iconOnly
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) {
            onChange(!checkRef.current.checked);
          }
        }}
        iconRenderFun={() => <FatCheck style={checkboxStyle} />}
        style={style}
        title="Checkbox"
      />
    </label>
  );
};

export default Checkbox;
