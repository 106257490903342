import {useCallback, useReducer} from 'react';

interface IssueState {
  error?: string;
  warning?: string;
  setError: (text: string) => void;
  setWarning: (text: string) => void;
  clearIssue: () => void;
}

interface BooleanIssueState {
  error?: boolean;
  warning?: boolean;
  setError: (error: boolean) => void;
  setWarning: (error: boolean) => void;
  clearIssue: () => void;
}

type SetErrorAction = {
  type: 'setError';
  payload: string;
};

type SetWarningAction = {
  type: 'setWarning';
  payload: string;
};

type ClearIssueAction = {
  type: 'clearIssue';
  payload: null;
};

interface IssueReducerState {
  error?: string;
  warning?: string;
}

type IssueReducerAction = SetErrorAction | SetWarningAction | ClearIssueAction;

const IssueReducer = (state: IssueReducerState, action: IssueReducerAction) => {
  const {payload, type} = action;

  if (type === 'setError') {
    return {...state, warning: undefined, error: payload};
  }
  if (type === 'setWarning') {
    return {...state, warning: payload, error: undefined};
  }
  if (type === 'clearIssue') {
    return {};
  }

  return state;
};

const useIssueText = (): IssueState => {
  const [{error, warning}, dispatch] = useReducer(IssueReducer, {
    error: undefined,
    warning: undefined,
  });

  const setError = useCallback(
    (text: string) => {
      dispatch({payload: text, type: 'setError'});
    },
    [dispatch],
  );

  const setWarning = useCallback(
    (text: string) => {
      dispatch({payload: text, type: 'setWarning'});
    },
    [dispatch],
  );

  const clearIssue = useCallback(() => {
    dispatch({payload: null, type: 'clearIssue'});
  }, [dispatch]);

  return {
    error,
    warning,
    setError,
    setWarning,
    clearIssue,
  };
};

export const useIssueBoolean = (): BooleanIssueState => {
  const [{error, warning}, dispatch] = useReducer(IssueReducer, {
    error: undefined,
    warning: undefined,
  });

  const setError = useCallback(
    (value: boolean) => {
      const text = value ? 'error' : undefined;
      dispatch({payload: text, type: 'setError'});
    },
    [dispatch],
  );

  const setWarning = useCallback(
    (value: boolean) => {
      const text = value ? 'warning' : undefined;
      dispatch({payload: text, type: 'setWarning'});
    },
    [dispatch],
  );

  const clearIssue = useCallback(() => {
    dispatch({payload: null, type: 'clearIssue'});
  }, [dispatch]);

  return {
    error: !!error,
    warning: !!warning,
    setError,
    setWarning,
    clearIssue,
  };
};

export default useIssueText;
