import React, {useState} from 'react';
import {
  add,
  sub,
  startOfWeek,
  isSameDay,
  isSameMonth,
  isBefore,
  isAfter,
  isSameYear,
} from 'date-fns';
import {format, parse} from 'utils/dateUtils';
import styles from './calendarPicker.module.scss';
import SelectInput from 'components/ui/select';

interface CalendarPickerProps {
  selectedDate?: Date;
  handleChange?: (newValue: any) => void;
  setShowCalendar?: (newValue: any) => void;
  mustBeBefore?: Date;
  mustBeAfter?: Date;
  exceptionDate?: Date;
  'data-test'?: string;
  dateFormat?: string;
  hideDays?: boolean;
  onMonthChange?: (newMonth: string) => void;
  onYearChange?: (newYear: string) => void;
}

const CalendarPicker = (props: CalendarPickerProps) => {
  const {
    selectedDate,
    handleChange,
    setShowCalendar,
    mustBeBefore,
    mustBeAfter,
    exceptionDate,
    dateFormat,
    hideDays,
    onMonthChange,
    onYearChange,
  } = props;

  const [currentMonth, setCurrentMonth] = useState<string>(
    selectedDate ? format(selectedDate, 'MM') : format(new Date(), 'MM'),
  );
  const [currentYear, setCurrentYear] = useState<string>(
    selectedDate ? format(selectedDate, 'yyyy') : format(new Date(), 'yyyy'),
  );

  const getMonthOptions = () => {
    let output = [];
    for (let i = 1; i <= 12; i++) {
      const parsedMonth = parse(i.toString(), 'L');
      output.push({
        label: format(parsedMonth, 'LLL'),
        value: format(parsedMonth, 'MM'),
      });
    }
    return output;
  };

  const getYearOptions = () => {
    let output = [];

    // default: start 25 years before this year and get 50 years
    let currentYear = selectedDate
      ? sub(selectedDate, {
          years: 25,
        })
      : sub(new Date(), {years: 25});
    // if min range is defined, start with that year
    if (mustBeAfter) {
      currentYear = mustBeAfter;
    }
    for (let i = 0; i < 50; i++) {
      // if max range is defined and reached, break loop
      if (
        mustBeBefore &&
        isAfter(currentYear, mustBeBefore) &&
        !isSameYear(currentYear, mustBeBefore)
      ) {
        break;
      }
      output.push({
        label: format(currentYear, 'yyyy'),
        value: format(currentYear, 'yyyy'),
      });
      currentYear = add(currentYear, {years: 1});
    }
    return output;
  };

  const createMonthObject = (inputMonth: string, inputYear: string) => {
    let output = [];

    // parse first day of month
    const firstDay = parse(`${inputMonth}/01/${inputYear}`, 'MM/dd/yyyy');
    // get the day the first week starts on
    let currentDay = startOfWeek(firstDay);

    // month grid builder (7 days * 6 weeks)
    for (let week = 0; week < 6; week++) {
      let weekArray = [];
      for (let day = 0; day < 7; day++) {
        weekArray.push(currentDay);
        currentDay = add(currentDay, {days: 1});
      }
      output.push(weekArray);
    }

    return output;
  };

  const renderMonth = (inputMonth: string, inputYear: string) => {
    const monthArray = createMonthObject(inputMonth, inputYear);

    return (
      <div className={styles.month}>
        {monthArray.map((week: [], index1) => {
          return (
            <div className={styles.week} key={index1}>
              {week.map((day: Date, index2) => {
                const sameDay = selectedDate
                  ? isSameDay(day, selectedDate)
                  : false;
                const sameMonth = isSameMonth(
                  day,
                  parse(`${inputMonth}${inputYear}`, 'MMyyyy'),
                );
                // default to allowing past dates
                let past = false;
                if (
                  mustBeAfter &&
                  isBefore(day, mustBeAfter) &&
                  !isSameDay(day, mustBeAfter) &&
                  !(exceptionDate && isSameDay(day, exceptionDate))
                ) {
                  past = true;
                }
                let tooFarFuture = false;
                if (
                  mustBeBefore &&
                  isAfter(day, mustBeBefore) &&
                  !isSameDay(day, mustBeBefore) &&
                  !(exceptionDate && isSameDay(day, exceptionDate))
                ) {
                  tooFarFuture = true;
                }
                return (
                  <div
                    key={index2}
                    className={[
                      styles.day,
                      sameDay ? styles.selected : '',
                      !sameMonth ? styles.notMonth : '',
                      past ? styles.disabled : '',
                      tooFarFuture ? styles.disabled : '',
                    ].join(' ')}
                    onClick={() => {
                      if (sameMonth) {
                        handleChange(format(day, dateFormat || 'MM/dd/yyyy'));
                        setShowCalendar(false);
                      }
                    }}>
                    {sameMonth ? format(day, 'd') : ''}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div id="calendarPicker" className={styles.calendarPicker}>
      <div className={styles.dropdownContainer}>
        <div>
          <SelectInput
            data-test={`${
              props['data-test'] ? `${props['data-test']}-` : ''
            }month-select`}
            options={getMonthOptions()}
            selected={currentMonth}
            onChange={(newMonth) => {
              if (onMonthChange) {
                onMonthChange(newMonth);
              }
              setCurrentMonth(newMonth);
            }}
            hideLabelSpacing={true}
            hideMessageSpacing={true}></SelectInput>
        </div>
        <div>
          <SelectInput
            data-test={`${
              props['data-test'] ? `${props['data-test']}-` : ''
            }year-select`}
            options={getYearOptions()}
            selected={currentYear}
            onChange={(newYear) => {
              if (onYearChange) {
                onYearChange(newYear);
              }
              setCurrentYear(newYear);
            }}
            hideLabelSpacing={true}
            hideMessageSpacing={true}></SelectInput>
        </div>
      </div>
      {currentYear && currentMonth && !hideDays && (
        <div>
          <div className={styles.daysOfWeek}>
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>Th</div>
            <div>F</div>
            <div>S</div>
          </div>
          {renderMonth(currentMonth, currentYear)}
        </div>
      )}
    </div>
  );
};

export default CalendarPicker;
