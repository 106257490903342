import React, {useState, useEffect, useRef, forwardRef, useMemo} from 'react';
import {ChromePicker} from 'react-color';
import styles from './editInput.module.scss';
import useIssueText from 'hooks/useIssueText';
import MarkdownText from 'components/ui/markdownText';
import {isValid, isAfter, add} from 'date-fns';
import {format, parse} from 'utils/dateUtils';
import {emailValidation, isSafeInt, phoneValidation} from 'utils/validations';
import CopyUrlButton from 'components/ui/copyUrlButton';
import CalendarPicker from 'components/ui/calendarPicker';
import useClickOutside from 'hooks/useClickOutside';
import Calendar from 'public/icons/calendar.svg';
import {useFormUpdate, useFormValue} from 'hooks/useFormUpdate';
import CurrencyInput from 'react-currency-input-field';
import {centsToDollars, dollarsToCents} from 'utils/price';
import {FORM_SUBMITTED, useFormContext} from 'providers/formProvider';
import Checkbox from 'components/ui/checkbox';
import {Currency, Person, OrderStage} from 'types';
import {dts} from 'e2e/selectors';
import Link from 'public/icons/link.svg';

export enum DateStatus {
  OK,
  INVALID,
  TOO_OLD,
  IN_PAST,
}

export type EditInputType =
  | 'number'
  | 'integer'
  | 'percent'
  | 'integerPercent'
  | 'string'
  | 'tel'
  | 'currency'
  | 'password'
  | 'minmax'
  | 'email'
  | 'date'
  | 'hexcode'
  | 'checkbox';

export type EditInputSize = 'normal' | 'large';

function inputTypeForPatchInputType(patchInputType: EditInputType): string {
  if (patchInputType === 'currency') {
    return 'string';
  }
  if (patchInputType === 'minmax') {
    return 'number';
  }
  return patchInputType;
}

export interface ColorPickerProps {
  initialValue: string;
  setFormError: (newError: boolean) => void;
  formNamespace: string;
  formKey: string;
  label: string;
  inputSize?: EditInputSize;
}

export interface EditInputProps
  extends Partial<React.InputHTMLAttributes<HTMLInputElement>> {
  disabled?: boolean;
  alwaysStatic?: boolean;
  label?: string;
  className?: string;
  labelClassName?: string;
  editInputClassName?: string;
  error?: boolean;
  message?: string;
  notApplicableClassName?: string;
  errorClassName?: string;
  inputValue: any;
  inputType?: EditInputType;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onChange?: (newValue: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  overrideRaw?: boolean;
  errorCallback?: (errorExist: boolean) => void;
  hideMessageSpacing?: boolean; // Hide the message spacing below the input
  hideLabelSpacing?: boolean; // Hide the label spacing above the input
  locked?: boolean; // grey out entry
  allowNegativeValues?: boolean;
  inputSize?: EditInputSize;
  iconRenderFun?: () => React.ReactNode;
  'data-test'?: string;
  fullWidth?: boolean;
  currency?: Currency;
  stage?: OrderStage;
  decimalLimit?: number;
  readOnly?: boolean;
  boldDisabledLabel?: boolean;
  maxlength?: number;
  beforeSignClassName?: string;
  /**
   * Forces the display of decimal digits to `decimalLimit`
   * a fixed length by padding with 0 as needed.
   *
   * Only applies to currency fields.
   */
  forceDisplayDecimalDigits?: boolean;
}
/**
 * Controlled input component that can be thought of as a prototype
 * that other input components inherit from and are built upon
 *
 * @param {EditInputProps} props include props for the input itself as well as some other elements, such as:
 * @param {string} props.label any text that should display before the input (i.e. a title or name for the input field)
 * @param {string} props.message any text that should display after the input (i.e. a validation error message for the input value)
 */
const EditInput = forwardRef<HTMLInputElement, EditInputProps>(
  (props: EditInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      labelClassName,
      editInputClassName,
      className,
      notApplicableClassName,
      beforeSignClassName,
      errorClassName,
      error,
      inputValue,
      inputType,
      message,
      onClick,
      onChange,
      onFocus,
      onBlur,
      disabled,
      alwaysStatic,
      label,
      overrideRaw,
      hideMessageSpacing,
      hideLabelSpacing,
      locked,
      iconRenderFun,
      inputSize = 'normal',
      fullWidth,
      errorCallback,
      currency,
      decimalLimit = 2,
      maxlength,
      boldDisabledLabel,
      forceDisplayDecimalDigits,
      ...inputProps
    } = props;
    const {error: internalError, setError, clearIssue} = useIssueText();

    let convertedInputValue = inputValue;
    if (inputType === 'currency') {
      const formattedInputValue = centsToDollars(inputValue, decimalLimit);
      // this fixes the decimal digits to a specified
      // length by padding with trailing 0s.
      // so we can only have it if the field is not editable
      // by the user.
      // otherwise it would interfere
      // with user input.
      convertedInputValue = forceDisplayDecimalDigits
        ? formattedInputValue.toFixed(decimalLimit)
        : formattedInputValue;
    }

    const [rawValue, setRawValue] = useState(convertedInputValue ?? '');
    const classNames = [
      styles.editInput,
      editInputClassName,
      styles[inputSize],
    ];
    const labelClassNames = [styles.editInputLabelText, styles[inputSize]];
    if (labelClassName) {
      labelClassNames.push(labelClassName);
    }

    if (!hideLabelSpacing) {
      labelClassNames.push(styles.editInputLabelSpacing);
    }

    const messageClassNames = [styles.message, styles[inputSize]];
    let value = inputValue;

    if (internalError || error) {
      labelClassNames.push(styles.error);
      messageClassNames.push(styles.error);
      classNames.push(styles.error);
    }

    useEffect(() => {
      // if the input changes, i.e. from a formValue restore then
      // we should detect and update the input
      if (
        inputValue !== null ||
        (inputValue !== '' &&
          ['number', 'percent', 'integer', 'integerPercent'].includes(
            inputType,
          ))
      ) {
        let convertedInputValue = inputValue;
        if (inputType === 'currency') {
          const formattedInputValue = centsToDollars(
            convertedInputValue,
            decimalLimit,
          );
          convertedInputValue = forceDisplayDecimalDigits
            ? formattedInputValue.toFixed(decimalLimit)
            : formattedInputValue;
        }
        setRawValue(convertedInputValue);
      }
    }, [inputValue]);

    if (disabled) {
      classNames.push(styles.disabled);
      if (!boldDisabledLabel) {
        labelClassNames.push(styles.disabled);
      }
      messageClassNames.push(styles.disabled);
    }

    const labelClasses = [styles.editInputLabel, labelClassName];
    if (fullWidth) {
      labelClasses.push(styles.fullWidth);
    }

    if (locked) {
      classNames.push(styles.locked);
    }
    if (props.inputType === 'minmax') {
      if ([undefined, null].includes(value)) {
        value = 0;
      }
      if (value === 0) {
        classNames.push(notApplicableClassName);
      } else if (value < 0) {
        classNames.push(errorClassName);
      }
    }

    if (alwaysStatic) {
      classNames.push(styles.static);
    }

    // push the right padding of the input to prevent clashing with icon
    if (iconRenderFun) {
      classNames.push(styles.paddingRight);
    }

    classNames.push(className);

    const beforeSignClassNames = [styles.beforeSign];

    if (beforeSignClassName) {
      beforeSignClassNames.push(beforeSignClassName);
    }

    if (inputType === 'currency' && currency) {
      const currencyClasses = {
        [Currency.eur]: styles.currencyEUR,
        [Currency.aud]: styles.currencyAUD,
        [Currency.gbp]: styles.currencyGBP,
      };
      const targetClass = currencyClasses[currency];

      if (targetClass) {
        beforeSignClassNames.push(targetClass);
      }
    }

    // previous one line for none currency inputs didnt allow 0 because it is falsey
    // extracted out here and unpacked for better readability
    let noneCurrencyValue = rawValue ?? '';
    if (overrideRaw || alwaysStatic) {
      noneCurrencyValue = inputValue;
    }

    return (
      <label className={labelClasses.join(' ')}>
        {!hideLabelSpacing && (
          <p className={labelClassNames.join(' ')}>{label}</p>
        )}
        <div
          className={[styles.editInputInputContainer, styles[inputType]].join(
            ' ',
          )}>
          <p className={beforeSignClassNames.join(' ')} />
          {inputType === 'currency' && (
            <CurrencyInput
              decimalsLimit={decimalLimit}
              data-test={props['data-test']}
              ref={ref}
              className={[styles.editInputField, ...classNames].join(' ')}
              value={rawValue}
              onValueChange={(value) => {
                if (onChange) {
                  const newNumberValue: number =
                    value && value.length >= 0 ? Number(value) : null;

                  // GraphQL 32-bit signed ints have a max limit of 2,147,483,647 and min limit of -2,147,483,648.
                  // Limiting the max raw dollar amount to 21 million and min to -21 million for now. - DJL
                  if (newNumberValue && newNumberValue > 21000000) {
                    setError('The amount must be 21,000,000 or less.');
                    errorCallback && errorCallback(true);
                    return;
                  } else if (newNumberValue && newNumberValue < -21000000) {
                    setError('The amount must be -21,000,000 or more.');
                    errorCallback && errorCallback(true);
                    errorCallback(true);
                    return;
                  }

                  setRawValue(value);

                  if (internalError) {
                    clearIssue();
                  }

                  errorCallback && errorCallback(false);

                  // valid number send it on
                  onChange(dollarsToCents(newNumberValue, decimalLimit));
                }
              }}
              onClick={(e) => {
                if (onClick) {
                  onClick(e);
                } else {
                  e.stopPropagation();
                }
              }}
              disabled={disabled || alwaysStatic}
              type={inputTypeForPatchInputType(inputType)}
              onFocus={() => {
                if (onFocus) {
                  onFocus();
                }
              }}
              disableAbbreviations
              placeholder="0.00"
              onBlur={() => {
                if (onBlur) {
                  onBlur();
                }
              }}
            />
          )}
          {inputType !== 'currency' && (
            <input
              {...inputProps}
              ref={ref}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={disabled || alwaysStatic}
              type={inputTypeForPatchInputType(inputType)}
              className={[styles.editInputField, ...classNames].join(' ')}
              checked={props.inputType === 'checkbox' && value}
              value={noneCurrencyValue}
              onBlur={() => {
                if (onBlur) {
                  onBlur();
                }
              }}
              onChange={(e) => {
                if (onChange) {
                  let newValue: string | number | boolean = e.target.value;

                  if (newValue.length > 0) {
                    if (props.inputType === 'number') {
                      newValue = parseFloat(newValue);
                    } else if (props.inputType === 'integer') {
                      newValue = parseInt(newValue);
                    } else if (props.inputType === 'minmax') {
                      newValue = parseFloat(newValue);
                    } else if (props.inputType === 'checkbox') {
                      newValue = !value;
                    }

                    if (['number', 'integer'].includes(props.inputType)) {
                      const isSafeNum = isSafeInt(newValue as number);
                      if (!isSafeNum) {
                        setError(
                          'The amount must be between 21,000,000 and -21,000,000.',
                        );
                        errorCallback && errorCallback(true);
                        return;
                      }
                    }
                  } else {
                    // GOD-1049: if a number/integer type field is cleared (e.g. plan/add-on max unit qty),
                    // this is meant to default the new value to null instead of "".
                    if (
                      props.inputType === 'number' ||
                      props.inputType === 'integer'
                    ) {
                      newValue = null;
                    }
                  }

                  setRawValue(newValue);

                  if (internalError) {
                    clearIssue();
                  }

                  onChange(newValue);
                }
              }}
              onFocus={() => {
                if (onFocus) {
                  onFocus();
                }
              }}
              maxLength={maxlength}
            />
          )}
          {iconRenderFun && iconRenderFun()}
          <p className={styles.afterSign} />
        </div>
        {!hideMessageSpacing && (
          <p className={messageClassNames.join(' ')}>
            <MarkdownText
              data-test={`${props['data-test']}-message`}
              text={internalError || message}
            />
          </p>
        )}
      </label>
    );
  },
);

const START_EPOCH = parse('01/01/1970', 'dd/mm/yyyy');
interface DateInputProps extends Omit<EditInputProps, 'onChange'> {
  onChange?: (newValue: any, status: DateStatus) => void;
  hideDays?: boolean;
  dateFormat?: string;
  onMonthChange?: (newMonth: string) => void;
  onYearChange?: (newYear: string) => void;
}
export const DateInput = (props: {futureOnly?: boolean} & DateInputProps) => {
  const {
    futureOnly,
    inputValue,
    onChange,
    hideDays,
    onMonthChange,
    onYearChange,
    dateFormat: initialDateFormat,
    ...rest
  } = props;
  const dateFormat = initialDateFormat || 'MM/dd/yyyy';
  const initialValue = inputValue ? format(inputValue, dateFormat) : null;
  const [rawValue, setRawValue] = useState(initialValue);
  const [parsedValue, setParsedValue] = useState<Date>();
  const {error, setError, clearIssue} = useIssueText();
  const [showCalendar, setShowCalendar] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setShowCalendar(false));
  useEffect(() => {
    if (rawValue && isValid(parse(rawValue, dateFormat))) {
      setParsedValue(parse(rawValue, dateFormat));
    }
  }, [rawValue]);
  useEffect(() => {
    setRawValue(initialValue);
    if (!initialValue) {
      setParsedValue(null);
    }
  }, [initialValue]);
  const onDateChange = (newValue: string) => {
    setRawValue(newValue);
    if (!newValue) {
      onChange(newValue, DateStatus.OK);
      if (error) {
        clearIssue();
      }
      return;
    }
    const date = new Date(newValue);
    if (!isValid(date)) {
      setError('Invalid date');
      onChange(date, DateStatus.INVALID);
    } else if (!isAfter(date, START_EPOCH)) {
      setError('Date is too far in the past');
      onChange(date, DateStatus.TOO_OLD);
    } else if (futureOnly && !isAfter(date, new Date())) {
      setError('Date must be in the future');
      onChange(date, DateStatus.IN_PAST);
    } else {
      onChange(date, DateStatus.OK);
      setParsedValue(date);
      if (error) {
        clearIssue();
      }
    }
  };
  return (
    <div ref={ref} style={{position: 'relative'}}>
      <EditInput
        {...props}
        placeholder={rest.placeholder || 'MM/DD/YYYY'}
        inputValue={rawValue}
        error={rest.error || !!error}
        message={error || rest.message}
        onChange={(newValue) => {
          onDateChange(newValue);
        }}
        onFocus={() => setShowCalendar(true)}
        iconRenderFun={() => (
          <Calendar
            className={styles.calendarIcon}
            onClick={() => setShowCalendar(true)}
          />
        )}
      />
      {showCalendar && (
        <div className={styles.calendarContainer}>
          <CalendarPicker
            selectedDate={parsedValue}
            handleChange={onDateChange}
            setShowCalendar={setShowCalendar}
            hideDays={hideDays}
            onMonthChange={onMonthChange}
            onYearChange={onYearChange}
            mustBeAfter={futureOnly ? add(new Date(), {days: 1}) : null}
          />
        </div>
      )}
    </div>
  );
};

/**
 * Wrapper for EditInput that suggests (but does not enforce) numerical input
 *
 * @param {EditInputProps} props input props, including
 * @param {EditInputType} props.inputType which probably should be set to one of
 *  - `number`
 *  - `integer`
 *  - `percent`
 *  - `integerPercent`
 */
export const NumericEditInput = forwardRef<HTMLInputElement, EditInputProps>(
  (props: EditInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    // TODO: why does this have a ref
    // TODO: make the onChange convert to number
    return <EditInput inputType="integer" {...props} ref={ref} />;
  },
);

export const CurrencyEditInput = (
  props: {
    errorOnEmptyValue?: boolean;
    showInternalError?: boolean;
  } & EditInputProps,
) => {
  const {
    onBlur,
    onChange,
    errorCallback,
    errorOnEmptyValue,
    currency,
    forceDisplayDecimalDigits,
    ...rest
  } = props;

  return (
    <EditInput
      {...rest}
      overrideRaw
      errorCallback={errorCallback}
      inputType={'currency'}
      currency={currency}
      forceDisplayDecimalDigits={forceDisplayDecimalDigits}
      onChange={(v) => {
        if (!rest.error && errorOnEmptyValue) {
          errorCallback(v !== 0 && !v);
        }
        if (onChange) {
          onChange(v);
        }
      }}
      onBlur={() => {
        if (onBlur) {
          onBlur();
        }
      }}
    />
  );
};

export const PercentageEditInput = (props: EditInputProps) => {
  const {
    inputValue,
    allowNegativeValues,
    message,
    onChange,
    errorCallback,
    error,
    ...rest
  } = props;
  const {error: internalError, setError, clearIssue} = useIssueText();

  const [rawValue, setRawValue] = useState<number>(inputValue);
  useEffect(() => {
    if (inputValue !== '' && inputValue !== null) {
      setRawValue(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    errorCallback && errorCallback(!!internalError);
  }, [errorCallback, internalError]);

  return (
    <EditInput
      {...rest}
      overrideRaw
      inputType={'percent'}
      error={error || !!internalError}
      message={message || internalError}
      inputValue={rawValue}
      onChange={(newValue) => {
        if (newValue === '') {
          newValue = 0;
        }

        if (newValue.length > 18) {
          // lol y tho
          setError('Too long');
          if (onChange) {
            onChange(newValue);
          }
          return;
        }

        // if starting a decimal, wait for the result before parsing
        if (!/^\d+\.$/.test(newValue)) {
          let decimalPlace = 0;
          if (newValue) {
            const digitsAfterDecimal = newValue.split('.')[1];
            if (digitsAfterDecimal) {
              decimalPlace = digitsAfterDecimal.length;
            }
          }
          const fixedDecimalPlace = Math.min(decimalPlace, 2);
          // otherwise continue
          const valueUpTo100Percent = newValue < 100 ? newValue : 100;

          if (!isNaN(valueUpTo100Percent)) {
            if (allowNegativeValues) {
              newValue = valueUpTo100Percent;
            } else {
              newValue =
                Math.abs(valueUpTo100Percent).toFixed(fixedDecimalPlace);
            }
            clearIssue();
            onChange(newValue);
          } else {
            setError('Invalid percentage');
            return;
          }
        }

        // always send the raw value so we capture decimal places
        setRawValue(newValue);
      }}
    />
  );
};

const PrimaryColor = (props: {
  initialValue: string;
  formValue: string;
  setFormError: (newError: boolean) => void;
  setFormValue: (newValue: string) => void;
  'data-test'?: string;
}) => {
  const {initialValue, setFormValue, formValue, setFormError} = props;
  const [showPicker, setShowPicker] = useState(false);
  const [rawHexValue, setRawHexValue] = useState(initialValue);

  return (
    <div className={styles.colorPicker}>
      <HexCodeInput
        data-test={dts.components.adminPage.settings.branding.color.input}
        label={'Primary'}
        inputValue={formValue || ''}
        onChange={setFormValue}
        errorCallback={setFormError}
        rawHexValue={rawHexValue}
        setRawHexValue={setRawHexValue}
      />
      <div
        className={styles.colorPreview}
        style={{backgroundColor: '#' + formValue}}
        onClick={() => {
          setShowPicker(true);
        }}
      />
      {showPicker && (
        <div className={styles.colorPickerPopup}>
          <div
            className={styles.colorPickerTool}
            onClick={() => setShowPicker(false)}
          />
          <ChromePicker
            disableAlpha={true}
            color={formValue ?? '000000'}
            onChange={(color: any) => {
              if (color?.hex) {
                setFormValue(color.hex.replace('#', ''));
                setRawHexValue(color.hex.replace('#', ''));
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export const ColorPicker = (props: ColorPickerProps) => {
  const {setFormError, formNamespace, formKey, initialValue} = props;
  const {setFormValue, formValue} = useFormUpdate(
    formNamespace,
    formKey,
    initialValue,
    true,
  );

  return (
    <div>
      <PrimaryColor
        initialValue={initialValue}
        formValue={formValue}
        setFormValue={setFormValue}
        setFormError={setFormError}
      />
    </div>
  );
};

export const HexCodeInput = (
  props: {
    setRawHexValue: (color: string) => void;
    rawHexValue: string;
  } & Partial<EditInputProps>,
) => {
  const {
    inputValue,
    message,
    onChange,
    errorCallback,
    error,
    setRawHexValue,
    rawHexValue,
    ...rest
  } = props;
  const {error: internalError, setError, clearIssue} = useIssueText();

  useEffect(() => {
    errorCallback(!!internalError);
  }, [errorCallback, internalError]);

  return (
    <EditInput
      {...rest}
      overrideRaw
      inputType={'hexcode'}
      error={error || !!internalError}
      message={message || internalError}
      inputValue={rawHexValue?.replace('#', '')}
      onChange={(newValue) => {
        let trimmedValue = newValue.replace('#', '');
        const isValid = /^[a-fA-F0-9]{6}$/.test(trimmedValue);
        if (isValid) {
          clearIssue();
          onChange(trimmedValue);
        } else {
          setError('Invalid hex code');
        }
        setRawHexValue(trimmedValue);
      }}
    />
  );
};

export const PhoneInput = (props: EditInputProps) => {
  const {inputValue, message, onChange, errorCallback, error, ...rest} = props;
  const [rawValue, setRawValue] = useState(inputValue);
  const {error: internalError, setError, clearIssue} = useIssueText();

  useEffect(() => {
    errorCallback(!!internalError);
  }, [errorCallback, internalError]);

  return (
    <EditInput
      {...rest}
      overrideRaw
      inputType={'tel'}
      error={error || !!internalError}
      message={message || internalError}
      inputValue={rawValue}
      onChange={(newValue) => {
        const isValid = phoneValidation.test(newValue);
        if (isValid) {
          clearIssue();
          onChange(newValue);
        } else {
          setError('Invalid phone number');
        }
        setRawValue(newValue);
      }}
    />
  );
};

export const StaticCopyInput = forwardRef<HTMLInputElement, EditInputProps>(
  (props: EditInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <EditInput
        ref={ref}
        locked
        overrideRaw
        iconRenderFun={() => (
          <CopyUrlButton
            url={props.inputValue}
            onClick={() => {
              if (props.onClick) {
                props.onClick(null);
              }
            }}
          />
        )}
        {...props}
      />
    );
  },
);

type StaticLinkInputProps = EditInputProps & {
  url: string;
  openInNewTab?: boolean;
};

export const StaticLinkInput = forwardRef<
  HTMLInputElement,
  StaticLinkInputProps
>((props: StaticLinkInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const {url, openInNewTab} = props;
  return (
    <EditInput
      ref={ref}
      locked
      overrideRaw
      iconRenderFun={() => (
        <a
          data-test={props['data-test'] + 'Button'}
          className={styles.linkInputIcon}
          target={openInNewTab ? '_blank' : '_self'}
          href={url}>
          <Link />
        </a>
      )}
      {...props}
    />
  );
});

/*
 * FormEditInput allows you to easily connect an EditInput component to a form namespace
 *
 *  handles required fields so it errors after being touched
 *  pushes errors in to form context so it can be read elsewhere for validation
 */
export interface FormEditInputProps extends Partial<EditInputProps> {
  namespace: string;
  useFormErrors?: boolean;
  formKey: string;
  label: string;
  initialValue?: string;
  optional?: boolean;
}

export const FormEditInput = (props: FormEditInputProps) => {
  const {
    namespace,
    error,
    optional,
    formKey,
    label,
    initialValue,
    useFormErrors,
    message,
    ...editInputProps
  } = props;

  const {setFormValue, setFormError, formError, formValue} = useFormUpdate(
    namespace,
    formKey,
    initialValue,
    false,
  );

  const {setFormValue: setTouched, formValue: touched} = useFormUpdate(
    namespace,
    formKey + 'Touched',
    !!initialValue,
    false,
  );

  const formSubmitted = useFormValue(namespace, FORM_SUBMITTED);

  let [inputError, inputErrorMessage] = useMemo(() => {
    // If useFormErrors is declared, then short circuit any checks and just
    // rely on errors stored in the FormState
    if (useFormErrors) {
      if (touched || formSubmitted) {
        return [!!formError, formError];
      } else {
        return [false, undefined];
      }
    }

    // only check if we have modified
    if (error !== true && formValue !== undefined) {
      // optional fields can not set an error
      if (optional) {
        return [false, ''];
      } else {
        return formValue.length === 0 ? [true, ''] : [false, ''];
      }
    }

    return [error, ''];
  }, [
    error,
    optional,
    formValue,
    formError,
    useFormErrors,
    touched,
    formSubmitted,
  ]);

  // Previously setFormError was being called from within the useMemo
  // calculating formError. This produces a React Error because you're
  // updating state while a render is happening.
  // The solution to this problem is to only call setFormError inside of a
  // useEffect, but since we don't want to have that useEffect repeatedly
  // run, we need to grab a self-updating ref to setFormError.
  const setFormErrorRef = useRef(setFormError);

  useEffect(() => {
    setFormErrorRef.current = setFormError;
  }, [setFormError]);

  useEffect(() => {
    if (!useFormErrors) {
      setFormErrorRef.current('Invalid');
    }
  }, [inputError, useFormErrors]);

  return (
    <EditInput
      data-test={props['data-test'] ?? namespace + formKey}
      inputValue={formValue}
      onChange={(newValue) => {
        setTouched(true);
        setFormValue(newValue);
      }}
      label={label}
      error={!!inputError}
      message={inputErrorMessage || message}
      {...editInputProps}
    />
  );
};

/*
 * EditPersonInput is a first, last name and email component with validations
 */
export const EditPersonInput = ({
  prefix,
  formNamespace,
  initialValue,
  useFormErrors,
}: {
  prefix: string;
  formNamespace: string;
  initialValue?: Person;
  useFormErrors?: boolean;
}) => {
  const {state} = useFormContext();
  const values = state?.[formNamespace]?.values;

  // validate fields are in error or not
  const email = values?.[prefix + 'Email'] ?? undefined;

  return (
    <>
      <div className={styles.editPersonInput}>
        <div className={styles.name}>
          <FormEditInput
            namespace={formNamespace}
            formKey={prefix + 'FirstName'}
            label={'First name'}
            placeholder={'Jane'}
            initialValue={initialValue?.firstName}
            useFormErrors={useFormErrors}
          />
          <FormEditInput
            namespace={formNamespace}
            formKey={prefix + 'LastName'}
            label={'Last name'}
            placeholder={'Smith'}
            initialValue={initialValue?.lastName}
            useFormErrors={useFormErrors}
          />
        </div>
      </div>
      <div className={styles.email}>
        <FormEditInput
          namespace={formNamespace}
          formKey={prefix + 'Email'}
          label={'Work email'}
          type={'email'}
          placeholder={'example@example.com'}
          error={email === undefined ? false : !emailValidation.test(email)}
          initialValue={initialValue?.email}
          useFormErrors={useFormErrors}
        />
      </div>
    </>
  );
};

export const FormEditCheckbox = (props: {
  formNamespace: string;
  formKey: string;
  initialValue?: boolean;
}) => {
  const {formNamespace, formKey, initialValue} = props;

  const {setFormValue, formValue} = useFormUpdate(
    formNamespace,
    formKey,
    initialValue,
    false,
  );

  return (
    <Checkbox
      className={styles.checkbox}
      checked={formValue}
      onChange={setFormValue}
      data-test={formNamespace + formKey}
    />
  );
};

export const InlineDisabledInput = (props: {
  value: string;
  'data-test'?: string;
}) => (
  <span className={styles.inlineDisabledInput} data-test={props['data-test']}>
    {props.value}
  </span>
);

export default EditInput;
