import React from 'react';
import parse from 'html-react-parser';
import styles from './markdownText.module.scss';

const MarkdownText = (props: {
  text: string;
  'data-test'?: string;
  pSpacing?: boolean;
  textOnly?: boolean;
  className?: string;
}) => {
  const {text, pSpacing, textOnly, className} = props;

  if (!text) {
    return null;
  }

  if (textOnly) {
    return <p>{parse(text.replace(/<[^>]+>/g, ''))}</p>;
  }

  const el = parse(text);

  const classes = [styles.md];
  if (pSpacing) {
    classes.push(styles.pSpacing);
  }

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')} data-test={props['data-test']}>
      {el}
    </div>
  );
};

export default MarkdownText;
