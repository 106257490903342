import React, {useState} from 'react';
import styles from './copyUrlButton.module.scss';
import Copy from 'public/icons/copy.svg';
import Check from 'public/icons/check.svg';
import Button from 'components/ui/button';

const CopyUrlButton = (props: {url: string; onClick?: Function}) => {
  const {url} = props;
  const [urlCopied, setUrlCopied] = useState(false);
  const copyIcon = <Copy className={styles.svgDisable} />;
  const checkIcon = <Check className={styles.svgDisable} />;

  return (
    <Button
      className={styles.copyButton}
      onClick={() => {
        if (props.onClick) props.onClick();

        if (!urlCopied) {
          return navigator.clipboard
            .writeText(url)
            .then(() => {
              setUrlCopied(true);
              setTimeout(() => {
                setUrlCopied(false);
              }, 3000);
            })
            .catch((err) => {
              console.error('Text not copied to clipboard', err);
            });
        }
        return false;
      }}
      ignoreTheme
      iconOnly
      iconRenderFun={() => (urlCopied ? checkIcon : copyIcon)}
      title="Copy"
    />
  );
};

export default CopyUrlButton;
